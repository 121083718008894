import { createTheme } from '@mui/material/styles'

export const defaultTheme = createTheme({
    palette: {
        primary: {
            light: '#60E6FF',
            main: '#008299',
            dark: '#115577',
            contrastText: '#fff',
        },
        background: {
            default: '#140222',
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    height: '100%',
                    width: '100%',
                    padding: '20px 40px',
                    gap: '8px',
                },
            },
        },
    },
})
